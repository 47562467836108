<template>
  <div class="box">
    <div class="box-header with-border">
      <h3>
        <previous-route-block />

        {{ page_title }}

        <router-link :to="{ name: 'employee.create' }" class="btn btn-success pull-right"
                     title="Добавить" type="button">
          <i class="fa fa-plus"></i>
        </router-link>
      </h3>
    </div>

    <div class="box-body">
      <transition name="fade">
        <div class="form-group">
          <div class="row">
            <label class="col-xl-3 col-md-6 col-sm-12 mb-1" for="filter-by-branch">
              <multiselect :allow-empty="true"
                           :options="branches"
                           :searchable="false"
                           :show-labels="true"
                           id="filter-by-branch"
                           @input="() => {
                             filter_employees.branch_id = branch ? branch.id : null;
                             updateFilters();
                           }"
                           deselect-label="Убрать"
                           label="name"
                           placeholder="Филиал"
                           required
                           select-label="Выбрать"
                           selected-label="Выбрано"
                           title="Филиал"
                           track-by="id"
                           v-model="branch">
                <template slot="noResult">Не найдено</template>
                <template slot="noOptions">Данные отсутствуют</template>
                <template slot="singleLabel" slot-scope="props">
                  {{ props.option.title }}, {{ props.option.address }}
                </template>
                <template slot="option" slot-scope="props">
                  {{ props.option.title }}, <span class="text-muted">{{ props.option.address }}</span>
                </template>
              </multiselect>
            </label>

            <label class="col-xl-3 col-md-6 col-sm-12 mb-1" for="filter-by-role">
              <multiselect :allow-empty="true"
                           :options="roles"
                           :searchable="false"
                           :show-labels="true"
                           id="filter-by-role"
                           @input="() => {
                             filter_employees.role_id = role ? role.id : null;
                             updateFilters();
                           }"
                           deselect-label="Убрать"
                           label="name"
                           placeholder="Должность"
                           required
                           select-label="Выбрать"
                           selected-label="Выбрано"
                           title="Должность"
                           track-by="id"
                           v-model="role">
                <template slot="noResult">Не найдено</template>
                <template slot="noOptions">Данные отсутствуют</template>
              </multiselect>
            </label>
          </div>
        </div>
      </transition>
      <table-items :columns="columns"
                   :data_search="data_search"
                   :filters_query="filter_employees"
                   :relations="relations"
                   :relations_count="relations_count"
                   component="employee-item"
                   api_resource="/employees">
      </table-items>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import TableItems from "./../../components/pagination/table-items";
import CardItems from "@/components/pagination/card-items.vue";
import Multiselect from "vue-multiselect";
import BranchService from "@/services/BranchService";
import RolesService from "@/services/RolesService";

Vue.component("employee-item", (resolve) => {
  return require(["./item"], resolve);
});

export default {
  name: "employees",

  components: {
    Multiselect,
    CardItems,
    TableItems,
  },

  props: {
    data_search: {
      type: String,
      required: false,
    },
  },

  metaInfo() {
    return {
      title: this.page_title,
    };
  },

  data: () => ({
    page_title: "Сотрудники",

    columns: [
      {
        title: "ID",
        class: null,
        style: null,
      },
      {
        title: null,
        class: null,
        style: null,
      },
      {
        title: null,
        class: null,
        style: null,
      },
      {
        title: "Имя",
        class: null,
        style: null,
      },
      {
        title: "Должность/Отдел",
        class: null,
        style: null,
      },
      {
        title: "Контакты",
        class: null,
        style: null,
      },
      {
        title: "Текущие штрафы",
        class: null,
        style: null,
      },
      {
        title: "Автомобилей",
        class: null,
        style: null,
      },
      {
        title: "Обновлён",
        class: null,
        style: null,
      },
      {
        title: null,
        class: null,
        style: null,
      },
    ],
    relations: [
      "role",
      "department",
      "branch",
      "city",
      "editor",
      "notification_channels",
      "day_off_responsible",
    ],
    relations_count: [
      "cars",
    ],

    branches: [],
    branch: null,
    roles: [],
    role: null,

    filter_employees: {
      branch_id: null,
      role_id: null,
    },
  }),

  async created() {
    try {
      this.branches = await BranchService.getItems();
      this.roles = await RolesService.getItems();
    } catch (error) {
      errorHandler(error);
    }
  },

  methods: {
    updateFilters() {
      this.EventBus.$emit("table-filter-changed");
    },
  },
};
</script>

<style scoped>

</style>
