var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"box-header with-border"},[_c('h3',[_c('previous-route-block'),_vm._v(" "+_vm._s(_vm.page_title)+" "),_c('router-link',{staticClass:"btn btn-success pull-right",attrs:{"to":{ name: 'employee.create' },"title":"Добавить","type":"button"}},[_c('i',{staticClass:"fa fa-plus"})])],1)]),_c('div',{staticClass:"box-body"},[_c('transition',{attrs:{"name":"fade"}},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-xl-3 col-md-6 col-sm-12 mb-1",attrs:{"for":"filter-by-branch"}},[_c('multiselect',{attrs:{"allow-empty":true,"options":_vm.branches,"searchable":false,"show-labels":true,"id":"filter-by-branch","deselect-label":"Убрать","label":"name","placeholder":"Филиал","required":"","select-label":"Выбрать","selected-label":"Выбрано","title":"Филиал","track-by":"id"},on:{"input":() => {
                           _vm.filter_employees.branch_id = _vm.branch ? _vm.branch.id : null;
                           _vm.updateFilters();
                         }},scopedSlots:_vm._u([{key:"singleLabel",fn:function(props){return [_vm._v(" "+_vm._s(props.option.title)+", "+_vm._s(props.option.address)+" ")]}},{key:"option",fn:function(props){return [_vm._v(" "+_vm._s(props.option.title)+", "),_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(props.option.address))])]}}]),model:{value:(_vm.branch),callback:function ($$v) {_vm.branch=$$v},expression:"branch"}},[_c('template',{slot:"noResult"},[_vm._v("Не найдено")]),_c('template',{slot:"noOptions"},[_vm._v("Данные отсутствуют")])],2)],1),_c('label',{staticClass:"col-xl-3 col-md-6 col-sm-12 mb-1",attrs:{"for":"filter-by-role"}},[_c('multiselect',{attrs:{"allow-empty":true,"options":_vm.roles,"searchable":false,"show-labels":true,"id":"filter-by-role","deselect-label":"Убрать","label":"name","placeholder":"Должность","required":"","select-label":"Выбрать","selected-label":"Выбрано","title":"Должность","track-by":"id"},on:{"input":() => {
                           _vm.filter_employees.role_id = _vm.role ? _vm.role.id : null;
                           _vm.updateFilters();
                         }},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}},[_c('template',{slot:"noResult"},[_vm._v("Не найдено")]),_c('template',{slot:"noOptions"},[_vm._v("Данные отсутствуют")])],2)],1)])])]),_c('table-items',{attrs:{"columns":_vm.columns,"data_search":_vm.data_search,"filters_query":_vm.filter_employees,"relations":_vm.relations,"relations_count":_vm.relations_count,"component":"employee-item","api_resource":"/employees"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }